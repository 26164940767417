<template>
    <div class="category-editor" v-show="categories.length >= 1">
        <div class="category-input-group">
            <vx-input-group class="mb-0">
                <vs-input placeholder="Kurzbechreibung (optional)" v-model="newCategory.short_description"/>
                <template slot="prepend">
                    <vs-input placeholder="Name" v-model="newCategory.name"/>
                </template>
                <template slot="append">
                    <vs-button color="primary" @click="addCategory" icon="add"></vs-button>
                </template>
            </vx-input-group>
        </div>
        <div class="category-list">
            <div class="category-item" v-for="(category, index) in categories" @dblclick="onDoubleClick(index, category)"
                 :class="index !== currentEditTarget && category.active === 0 ? 'not-active' : ''" >
                <div class="category-item__label">
                    <template v-if="index === currentEditTarget">
                        <vs-input size="small" v-model="editClone.name" style="width: 42%"></vs-input>
                        <vs-input size="small" v-model="editClone.short_description" style="width: 42%" class="ml-2"></vs-input>
                        <span><vs-checkbox  v-model="editClone.active" class="float-left"/> Aktive</span>
                    </template>
                    <template v-else >
                        <span class="name">{{category.name}}</span>
                        <span class="usable-on">{{category.short_description}}</span>
                    </template>

                    <template v-if="index === currentEditTarget">
                         <span class="category-item__label-delete" @click="currentEditTarget=null">
                            <vs-icon icon="close"></vs-icon>
                        </span>
                        <span class="category-item__label-check" @click="saveCategory">
                            <vs-icon icon="done"></vs-icon>
                        </span>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../api";

    export default {
        name: "CostCenterEditor",
        data() {
            return {
                initialCategories: [],
                categories: [],
                currentEditTarget: null,
                editClone: null,
                newCategory: {
                    active: true,
                    name: null,
                    short_description: null,
                }
            }
        },

        created() {
            this.fetchCategories();
        },

        methods: {
            fetchCategories() {
                ApiService.get('cost-center').then(response => {
                    if (response.data.status !== 'success') {
                        return;
                    }
                    this.$emit('fetched');
                    this.categories = response.data.result.result;
                    this.initialCategories = response.data.result.result;
                });
            },
            addCategory() {
                ApiService.post('cost-center', this.newCategory).then(response => {
                   this.fetchCategories();
                   this.newCategory.name = null;
                    this.newCategory.short_description = null;
                    this.newCategory.active = true;

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Kategorie wurde erfolgreich gespeichert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });
                }).catch((error) => {
                    if (error.response.data.message) {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.response.data.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                });
            },

            saveCategory() {
                ApiService.put('cost-center/'+ this.editClone.id, this.editClone).then(response => {
                    this.fetchCategories();

                    this.currentEditTarget = null;

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Kategorie wurde erfolgreich gespeichert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });
                }).catch((error) => {

                    this.currentEditTarget = null;

                    if (error.response.data.message) {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.response.data.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                });
            },
            onDoubleClick(index, category) {
                this.currentEditTarget = index;
                this.editClone =  JSON.parse(JSON.stringify(category));
            }
        }
    }
</script>

<style lang="scss" scoped>
.category-item.not-active {
    color: #b4b4b4;
}
</style>