<template>
  <vs-popup :title="title" :active="active" @close="onClickClose" class="absence-type-modal">

    <template v-if="data">
      <vs-row class="mb-6" vs-align="center">
        <vs-col vs-w="3" class="font-bold">Bezeichnung:</vs-col>
        <vs-col vs-w="8">
          <vs-input v-model="data.name"></vs-input>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col class="w-auto font-bold">
          <vs-switch color="success" v-model="data.paid"/>
        </vs-col>
        <vs-col class="pl-4 w-auto">
          Bezahlte Abwesenheit
        </vs-col>
      </vs-row>

      <vs-row class="mb-4" vs-align="center">
        <vs-col class="w-auto font-bold">
          <vs-switch color="success" v-model="data.affect_absence_quotas"/>
        </vs-col>
        <vs-col class="pl-4 w-auto">
          Auswirkung auf Abwesenheitskontingenten
        </vs-col>
      </vs-row>

      <div class="flex justify-end mt-6">
        <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
        <vs-button color="success" class="ml-4" @click="onClickSave">Speichern</vs-button>
      </div>

    </template>

  </vs-popup>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "AbsenceTypeModal",

  props: {
    active: {
      type: Boolean,
      default: false
    },
    absenceType: {
      type: Object,
      default: () => {
        return null;
      }
    },
  },

  data() {
    return {
      data: null,
    }
  },

  computed: {
    title() {
      if (this.isNew) {
        return 'Abwesenheitstyp erstellen';
      }

      return 'Abwesenheitstyp bearbeiten';
    },

    isNew() {
      if (!this.data) {
        return true;
      }

      return !this.data.id;
    },
  },

  methods: {
    onClickClose() {
      this.closePopup();
    },
    closePopup() {
      this.$emit('close');
      this.$emit('update:active', false)
    },
    onClickSave() {
      this.$emit('save', this.data);
    },
  },

  watch: {
    active: {
      immediate: true,
      handler(value) {
        if (value === true) {
          this.data = cloneDeep(this.absenceType);
        }
      }
    }
  },

}
</script>

<style scoped>

</style>