<template>
  <div class="settings-detail detail-absence-types">

    <vs-row vs-justify="center" class="pt-2 mb-4">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <vx-card title="Abwesenheitstypen">
          <vs-button
              @click="onCreateAbsenceType"
              class="mb-2"
              color="success"
              type="filled"
              size="small">
            Abwesenheitstyp Hinzufügen
          </vs-button>

          <vs-table :data="absenceTypes" hoverFlat stripe>
            <template slot="thead">
              <vs-th>Bezeichnung</vs-th>
              <vs-th>Bezahlt</vs-th>
              <vs-th>Kontingent</vs-th>
              <vs-th class="text-right">Aktion</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(absenceType, indextr) in data">
                <vs-td :data="absenceType.name">
                  {{ absenceType.name }}
                </vs-td>

                <vs-td :data="absenceType.paid">
                  <vs-icon icon="check_circle" class="text-success" v-if="absenceType.paid"></vs-icon>
                  <vs-icon icon="cancel" class="text-danger" v-else></vs-icon>
                </vs-td>

                <vs-td :data="absenceType.affect_absence_quotas">
                  <vs-icon icon="check_circle" class="text-success" v-if="absenceType.affect_absence_quotas"></vs-icon>
                  <vs-icon icon="cancel" class="text-danger" v-else></vs-icon>
                </vs-td>

                <vs-td class="text-right">
                  <vs-button
                      @click="onEditAbsenceType(absenceType)"
                      icon="edit"
                      color="primary"
                      type="filled"
                      size="small">
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

        </vx-card>
      </vs-col>
    </vs-row>

    <vs-row vs-justify="center" class="pt-4 mb-4">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <vx-card title="Automatische Pausenzeiten">
          <vs-button
              @click="onCreateRestingTime"
              class="mb-2"
              color="success"
              type="filled"
              size="small">
            Pausenzeit Hinzufügen
          </vs-button>

          <vs-table :data="restingTimes" hoverFlat stripe>
            <template slot="thead">
              <vs-th>Min. Minuten</vs-th>
              <vs-th>Dauer</vs-th>
              <vs-th class="text-right">Aktion</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(restingTime, indextr) in data">
                <vs-td :data="restingTime.min_minutes">
                  {{ restingTime.min_minutes }} ({{ restingTime.min_minutes | hours }}h)
                </vs-td>

                <vs-td :data="restingTime.duration">
                  {{ restingTime.duration }}
                </vs-td>

                <vs-td class="text-right">
                  <vs-button
                      @click="onEditRestingTime(restingTime)"
                      icon="edit"
                      color="primary"
                      type="filled"
                      size="small">
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

        </vx-card>
      </vs-col>
    </vs-row>

    <vs-row vs-justify="center" class="pt-2 mb-4">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <AbsenceNotifications />
      </vs-col>
    </vs-row>

    <vs-row vs-justify="center" class="pt-2 mb-4">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <SpecialEventsCategory />
      </vs-col>
    </vs-row>


    <absence-type-modal
        @save="onSaveAbsenceType"
        :active.sync="absenceTypePopupActive"
        :absence-type="currentAbsenceType">
    </absence-type-modal>

    <resting-time-modal
        @save="onSaveRestingTime"
        @delete="onDeleteRestingTime"
        :active.sync="restingTimePopupActive"
        :resting-time="currentRestingTime">
    </resting-time-modal>

  </div>
</template>

<script>
import ApiService from "../../../../api";
import AbsenceTypeModal from "./AbsenceTypeModal";
import ModelFactory from "../../../../mixins/model/model-factory";
import RestingTimeModal from "./RestingTimeModal";
import AbsenceNotifications from "@/views/administration/general-settings/components/AbsenceNotifications.vue";
import SpecialEventsCategory from "@/views/administration/general-settings/components/SpecialEventsCategory.vue";

export default {
  name: "WorkTimeSettings",
  components: {SpecialEventsCategory, AbsenceNotifications, RestingTimeModal, AbsenceTypeModal},
  data() {
    return {
      isLoading: true,
      absenceTypes: [],
      currentAbsenceType: null,
      absenceTypePopupActive: false,
      restingTimes: [],
      currentRestingTime: null,
      restingTimePopupActive: null
    }
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.isLoading = true;

      const requests = [ApiService.get('absence-type'), ApiService.get('resting-time')];

      Promise.all(requests).then(responses => {
        this.absenceTypes = responses[0].data.result;
        this.restingTimes = responses[1].data.result;
        this.isLoading = false;
      });
    },

    openAbsencePopup(absenceType) {
      this.currentAbsenceType = absenceType;
      this.absenceTypePopupActive = true;
    },
    onEditAbsenceType(absenceType) {
      this.openAbsencePopup(absenceType);
    },

    onCreateAbsenceType() {
      this.openAbsencePopup(ModelFactory.create('absence_types'));
    },

    openRestingTimePopup(restingTime) {
      this.currentRestingTime = restingTime;
      this.restingTimePopupActive = true;
    },

    onEditRestingTime(restingTime) {
      this.openRestingTimePopup(restingTime);
    },

    onCreateRestingTime() {
      this.openRestingTimePopup(ModelFactory.create('resting_times'));
    },

    onSaveAbsenceType(data) {
      if (data.id) {
        this.editAbsenceType(data);
        return;
      }

      this.createAbsenceType(data);
    },

    editAbsenceType(data) {
      ApiService.put('absence-type/' + data.id, data).then(response => {
        this.absenceTypePopupActive = false;
        response.successNotifier('Der Abwesenheitstyp wurde gespeichert');
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    createAbsenceType(data) {
      ApiService.post('absence-type', data).then(response => {
        this.absenceTypePopupActive = false;
        response.successNotifier('Der Abwesenheitstyp wurde erstellt');
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    onSaveRestingTime(data) {
      if (data.id) {
        this.editRestingTime(data);
        return;
      }

      this.createRestingTime(data);
    },

    onDeleteRestingTime(data) {
      ApiService.delete('resting-time/' + data.id).then(response => {
        this.restingTimePopupActive = false;
        response.successNotifier('Die Automatische Pausenzeit wurde gelöscht');
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    editRestingTime(data) {
      ApiService.put('resting-time/' + data.id, data).then(response => {
        this.restingTimePopupActive = false;
        response.successNotifier('Die Automatische Pausenzeit wurde gespeichert');
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

    createRestingTime(data) {
      ApiService.post('resting-time', data).then(response => {
        this.restingTimePopupActive = false;
        response.successNotifier('Die Automatische Pausenzeit  wurde erstellt');
        this.fetchData();
      }).catch(error => {
        error.handleUnkown = true;
        error.handleGlobally();
      });
    },

  },

  filters: {
    hours(minutes) {
      if(!minutes) {
        return 0;
      }

      return (parseFloat(minutes) / 60).toFixed(2);
    }
  }
}
</script>

<style scoped>

</style>