import General from './components/General';
import CategoriesTags from './components/CategoriesTags';
import WorkTimeSettings from './components/WorkTimeSettings';
import CostCenter from './components/CostCenter';

const MenuItems = [
    { label: 'Allgemeines', name: 'general', icon: 'SettingsIcon', component: General },
    { label: 'Kategorien & Tags', name: 'categories-tags', icon: 'ListIcon', component: CategoriesTags },
    { label: 'Kostenstellen', name: 'cost-center', icon: 'ListIcon', component: CostCenter },
    { label: 'Arbeitszeiteinstellungen', name: 'absence-types', icon: 'ClockIcon', component: WorkTimeSettings },
];

export default MenuItems;