<template>
    <div class="settings-detail detail-general">
        <div id="settings-detail--loader" class="vs-con-loading__container overflow-visible">

        <div class="detail-form">
            <template v-if="data.length > 1">

                <div class="p-4 w-3/4 pb-20">
                    <div class="vx-row mb-6 items-top" v-for="config in data">
                        <div class="vx-col sm:w-1/3 w-full pt-2">
                            <span>{{getLongName(config.name)}}:</span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">

                            <template v-if="config.name === 'default_signature'">
                                <editor api-key="qxbzwh7n69tctq7p5bta4qtatjs7c35wi7juwgf2rcpf29lu" :init="initTinyMce" v-model="config.value" />
                            </template>
                            <template v-else>
                                <vs-input class="w-full" v-model="config.value"/>
                            </template>
                        </div>
                    </div>
                </div>

            </template>
        </div>

        <div class="detail-footer p-2" v-if="data.length > 1">
            <vs-button :disabled="!changedConfigItems" @click="onCancel">Abbrechen</vs-button>
            <vs-button color="success"  :disabled="!changedConfigItems" @click="onSave">Speichern</vs-button>
        </div>

        </div>
    </div>
</template>

<script>
    import ApiService from "../../../../api";
    import Editor from '@tinymce/tinymce-vue'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    const isEqual = require('lodash.isequal');
    const isEmpty = require('lodash.isempty');

    export default {
        name: "General",
        components: {
            VuePerfectScrollbar,
            Editor
        },
        data() {
            return {
                data: [],
                initialData: [],
                configNames: [
                    {name: 'contract_mail_receiver', longName: 'Vertrags Benachrichtunges Empfänger'},
                    {name: 'default_signature', longName: 'Default Signatur'}
                ],
                initTinyMce: {
                    height: 300,
                    menubar: true,
                    plugins: "code table"
                },
                scrollbarSettings: {
                    maxScrollbarLength: 60,
                    wheelSpeed: 0.30,
                },
            }
        },
        mounted() {
            this.fetchConfig();
        },
        methods: {
            openLoading() {
                this.$vs.loading({container: '#settings-detail--loader', scale: 0.6});
            },

            closeLoading() {
                this.$vs.loading.close('#settings-detail--loader > .con-vs-loading');
            },

            fetchConfig() {
                this.openLoading();
                ApiService.get('configs').then((response) => {
                    this.closeLoading();
                    this.data = response.data.result;
                    this.initialData = JSON.parse(JSON.stringify(this.data));
                }).catch((response) => {
                    this.closeLoading();
                    this.$vs.notify({
                        title: 'Error',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },

            getLongName(name) {
                const configName = this.configNames.find(item => item.name === name);

                if (!configName) {
                    return name;
                }

                return configName.longName
            },

            onCancel() {
                this.data = JSON.parse(JSON.stringify(this.initialData));
            },

            onSave() {
                if(!this.changedConfigItems) {
                    return;
                }

                this.openLoading();

                ApiService.put('configs', {items: this.changedConfigItems}).then((response) => {
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Änderung wurde erfolgreich gespeichert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                    this.fetchConfig();

                }).catch((response) => {
                    this.closeLoading();

                    this.$vs.notify({
                        title: 'Error',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });

                    this.fetchConfig();
                });
            }
        },

        computed: {
            changedConfigItems() {
                let changed = [];

                if(this.initialData.length === 0) {
                    return false;
                }

                this.data.forEach((object, key)  => {
                    if (!isEqual(this.data[key].value, this.initialData[key].value)) {
                        changed.push(object);
                    }
                });

                return ! isEmpty(changed) ? changed : false;
            },
        }

    }
</script>

<style scoped>

</style>