<template>
  <vx-card title="Kategorien und Farben von Geburtstagen, Abwesenheiten, ..." ref="card">

    <vs-table :data="configValue" hoverFlat stripe v-if="dataFetchedInitial" class="mb-4">
      <template slot="thead">
        <vs-th>Abwesenheits-Typ</vs-th>
        <vs-th>Kategorie</vs-th>
        <vs-th>Farbe</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(absence_type, indextr) in data">
          <vs-td>
            {{ absence_type.absence_type_name }}
          </vs-td>

          <vs-td>
            <vs-select placeholder="Auswählen" v-model="absence_type.category_id">
              <vs-select-item
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category.id"
                  :text="category.name"
              />
            </vs-select>
          </vs-td>

          <vs-td>
            <input type="color" v-model="absence_type.color">
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-button
        color="success"
        type="filled"
        v-if="dataFetchedInitial"
        :disabled="loading"
        @click="updateData"
    >
      Speichern
    </vs-button>

  </vx-card>
</template>

<script>
import ApiService from "@/api/index.js";

export default {
  name: "SpecialEventsCategory",

  data() {
    return {
      categories: [],
      absenceTypes: [],
      configs: {},
      config: {},
      configValue: [],
      dataFetchedInitial: false,
      loading: false,
    }
  },

  methods: {
    fetchData() {
      this.$refs.card.refreshcard();

      this.saveData = [];

      const requests = [
          ApiService.get('categories?filter[0][field]=usable_on&filter[0][value]=calendar'),
          ApiService.get('absence-type'),
          ApiService.get('configs'),
      ];

      Promise.all(requests).then(responses => {

        this.categories = responses[0].data.result;
        this.absenceTypes = responses[1].data.result;

        const configs = responses[2].data.result;
        const config = configs.find((val) => {
          return val.name === 'special_events_category';
        });

        this.configs = configs;
        this.config = config;

        if (config) {
          this.configValue = JSON.parse(atob(config.value));

          this.configValue = this.configValue.map((config) => {

            let absence_type_name = null;

            if (config.absence_type_id === 10000000000) {
              absence_type_name = "Geburtstag";
            } else if (config.absence_type_id === 10000000001) {
              absence_type_name = "Jubiläum";
            } else {
              absence_type_name = this.absenceTypes.find((absenceType) => {
                return absenceType.id === config.absence_type_id;
              }).name;
            }

            if (!!absence_type_name) {
              return {
                absence_type_id: config.absence_type_id,
                absence_type_name: absence_type_name,
                category_id: config.category_id,
                color: config.color,
              };
            }
          });

          this.absenceTypes.forEach((absenceType) => {
            const find = this.configValue.find((config) => {
              return absenceType.id === config.absence_type_id;
            });

            if (!find) {
              this.configValue = [].concat(this.configValue, [{
                absence_type_id: absenceType.id,
                absence_type_name: absenceType.name,
                category_id: null,
                color: null,
              }])
            }
          });

          const birthday = this.configValue.find((config) => {
            return config.absence_type_id === 10000000000;
          });

          const anniversary = this.configValue.find((config) => {
            return config.absence_type_id === 10000000001;
          });

          if (!birthday) {
            this.configValue.push({
              absence_type_id: 10000000000,
              absence_type_name: 'Geburtstag',
              category_id: null,
              color: null,
            });
          }

          if (!anniversary) {
            this.configValue.push({
              absence_type_id: 10000000001,
              absence_type_name: 'Jubiläum',
              category_id: null,
              color: null,
            });
          }
        } else {
          this.absenceTypes.forEach((absenceType) => {
            this.configValue.push({
              absence_type_id: absenceType.id,
              absence_type_name: absenceType.name,
              category_id: null,
              color: null,
            });
          });

          this.configValue.push({
            absence_type_id: 10000000000,
            absence_type_name: 'Geburtstag',
            category_id: null,
            color: null,
          });

          this.configValue.push({
            absence_type_id: 10000000001,
            absence_type_name: 'Jubiläum',
            category_id: null,
            color: null,
          });
        }

        this.dataFetchedInitial = true;

        this.$refs.card.removeRefreshAnimation();

      });
    },

    async updateData() {
      this.$refs.card.refreshcard();
      this.loading = true;

      let value = this.configValue.map((save) => {
        return `{"absence_type_id": ${save.absence_type_id}, "category_id": ${save.category_id}, "color": "${save.color}"}`;
      }).join(',');

      if (Array.from(value)[0] === ",") {
        value = `[${value.substring(1)}]`;
      } else {
        value = `[${value}]`;
      }

      value = btoa(value);

      if (this.config) {
        await ApiService.put(`configs/${this.config.id}`, {
          name: 'special_events_category',
          value: value
        })
            .then(() => {
              this.fetchData();
            });
      } else {
        await ApiService.post('configs', {
          name: 'special_events_category',
          value: value
        })
            .then(() => {
              this.fetchData();
            });
      }

      this.loading = false;
      this.$refs.card.removeRefreshAnimation();
    }
  },

  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>

</style>