<template>
  <vx-card title="Abwesenheitsbenachrichtigungen" ref="card">

    <vs-row>

      <vs-col class="con-select sm:w-1/2 w-full">
        <vs-select
            label="Nutzer hinzufügen"
            placeholder="Nutzer auswählen"
            v-model="selectedUser"
        >
          <vs-select-item
              v-for="(user, index) in users"
              :key="index"
              :value="user.id"
              :text="user.name"
          />
        </vs-select>
      </vs-col>

      <vs-col class="sm:w-1/2 w-full flex">
        <div class="mt-auto">
          <vs-button
              color="success"
              type="filled"
              :disabled="isLoading"
              @click="addUser"
          >
            Hinzufügen
          </vs-button>
        </div>
      </vs-col>

    </vs-row>

    <div v-if="initialNotifiedUsers.length > 0">

      <p class="mt-5 mb-3">
        Benachrichtigungen werden versendet an ...
      </p>

      <vs-table :data="initialNotifiedUsers" hoverFlat stripe>
        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th class="text-right">Aktion</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(user, indextr) in data">
            <vs-td :data="user.name">
              {{ user.name }}
            </vs-td>

            <vs-td class="text-right">
              <vs-button
                  icon="delete"
                  color="primary"
                  type="filled"
                  size="small"
                  @click="removeUser(user.id)"
              >
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </div>

  </vx-card>
</template>

<script>
import ApiService from "@/api/index.js";

export default {
  name: "AbsenceNotifications",

  data() {
    return {
      isLoading: true,

      initialUsers: [],
      initialConfig: [],
      initialNotifiedUsersConfig: null,
      initialNotifiedUsers: [],

      selectedUser: null,
    }
  },

  computed: {

    users() {

      return this.initialUsers.filter((user) => {
        return !(this.initialNotifiedUsers.find((user2) => {
          return user2.id === user.id;
        }));
      });

    }

  },

  methods: {

    fetchData() {
      this.selectedUser = null;
      this.isLoading = true;
      this.$refs.card.refreshcard();

      const requests = [
        ApiService.get('users'),
        ApiService.get('configs'),
      ];

      Promise.all(requests).then(async (responses) => {
        this.initialUsers = responses[0].data.result;
        this.initialConfig = responses[1].data.result;

        const notofiedUsersConfig = this.initialConfig.find((config) => { return config.name === 'absence_notification' });

        if (notofiedUsersConfig) {
          this.initialNotifiedUsersConfig = notofiedUsersConfig;

          this.initialNotifiedUsers = [];

          const userIds = notofiedUsersConfig.value.split(";");

          for (let i = 0; i < userIds.length; i++) {
            const userId = userIds[i];

            if (userId === "") {
              continue;
            }

            const user = await ApiService.get(`users/${userId}`).then((response) => {
              return response.data.result;
            });

            this.initialNotifiedUsers.push({
              name: user.name,
              id: user.id,
            })
          }
        }

        this.isLoading = false;
        this.$refs.card.removeRefreshAnimation();
      });

    },

    addUser() {

      if (!this.selectedUser) {
        return false;
      }

      if (!this.initialNotifiedUsersConfig) {

        this.isLoading = true;
        this.$refs.card.refreshcard();

        ApiService.post('configs', {
          name: 'absence_notification',
          value: `${this.selectedUser}`
        })
            .then(() => {
              this.fetchData();
            });

      } else {

        this.isLoading = true;
        this.$refs.card.refreshcard();

        let newValue = "";

        if (this.initialNotifiedUsersConfig.value && this.initialNotifiedUsersConfig.value.replaceAll(" ", "") !== "") {
          newValue = `${this.initialNotifiedUsersConfig.value};${this.selectedUser}`;
        } else {
          newValue = this.selectedUser;
        }

        ApiService.put(`configs/${this.initialNotifiedUsersConfig.id}`, {
          name: 'absence_notification',
          value: newValue
        })
            .then(() => {
              this.fetchData();
            });

      }

    },

    removeUser(userId) {

      this.isLoading = true;
      this.$refs.card.refreshcard();

      const newValue = this.initialNotifiedUsers.reduce((result, user) => {
        if (user.id !== userId) {
          result.push(user.id);
        }

        return result;
      }, []).join(";");

      ApiService.put(`configs/${this.initialNotifiedUsersConfig.id}`, {
        name: 'absence_notification',
        value: newValue
      })
          .then(() => {
            this.fetchData();
          });

    }

  },

  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>

</style>