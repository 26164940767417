<template>
    <div class="settings-detail detail-categories-tags">
        <div id="settings-detail--loader" class="vs-con-loading__container overflow-visible">
            <div class="detail-categories-tags-content">
                <div class="w-full" v-show="fetched">
                    <div class="p-6 flex flex-col justify-center w-full m-auto" style="max-width: 500px;">
                        <p class="text-md text-left w-full mb-3 uppercase font-bold">Kategorien</p>

                        <category-editor class="w-full mb-6" @fetched="categoriesLoaded=true"></category-editor>

                        <p class="text-md text-left w-full mb-3 uppercase font-bold">Tags</p>

                        <tag-editor class="w-full mb-4" @fetched="tagsLoaded=true"></tag-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CategoryEditor from "../../../../components/search/CategoryEditor";
    import TagEditor from "../../../../components/search/TagEditor";

    export default {
        name: "CategoriesTags",
        components: {TagEditor, CategoryEditor},
        data() {
            return {
                scrollbarSettings: {
                    maxScrollbarLength: 60,
                    wheelSpeed: 0.30,
                },
                categoriesLoaded: false,
                tagsLoaded: false
            }
        },
        computed: {
            fetched() {
                return this.categoriesLoaded && this.tagsLoaded;
            }
        },
        mounted() {
            this.openLoading();
        },
        methods: {
            openLoading() {
                this.$vs.loading({container: '#settings-detail--loader', scale: 0.6});
            },

            closeLoading() {
                this.$vs.loading.close('#settings-detail--loader > .con-vs-loading');
            },
        },
        watch: {
            fetched(value) {
                if (value) {
                    this.closeLoading();
                }
            }
        }
    }
</script>

<style scoped>

</style>