<template>
    <div class="general-settings border border-solid d-theme-border-grey-light rounded relative overflow-hidden shadow">
        <vs-sidebar class="items-no-padding background-absolute" parent="#todo-app" :click-not-close="clickNotClose"
                    :hidden-background="clickNotClose" v-model="isSidebarActive">
            <VuePerfectScrollbar class="todo-scroll-area" :settings="scrollbarSettings">

                <div class="pt-4">
                    <template v-for="menuItem in menuItems">
                        <router-link tag="span" class="flex mb-2 p-2 pl-6 pr-6 cursor-pointer"
                                     :class="[{'border-l-2 border-primary text-primary pl-0': currentSetting == menuItem.name}]"
                                     :to="`${baseUrl}/${menuItem.name}`" :key="menuItem.name">

                            <feather-icon :icon="menuItem.icon"
                                          :svgClasses="[{'text-primary stroke-current': currentSetting == menuItem.name}, 'h-6 w-6']"></feather-icon>
                            <span class="text-lg ml-3">{{ menuItem.label }}</span>
                        </router-link>
                    </template>

                </div>

            </VuePerfectScrollbar>
        </vs-sidebar>
        <div :class="{'sidebar-spacer': clickNotClose}"
             class="app-fixed-height border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light app-fixed-height overflow-y-scroll">
            <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light">
                <!-- TOGGLE SIDEBAR BUTTON -->
                <feather-icon class="md:inline-flex lg:hidden m-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleTodoSidebar(true)" />
            </div>

            <component :is="currentComponent"></component>
        </div>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import MenuItems from './general-settings-menu';

    export default {
        name: "GeneralSettings",
        components: {
            VuePerfectScrollbar
        },
        data() {
            return {
                menuItems: MenuItems,
                clickNotClose: true,
                isSidebarActive: true,
                scrollbarSettings: {
                    maxScrollbarLength: 60,
                    wheelSpeed: 0.30,
                },
            }
        },
        created() {
            this.setSidebarWidth()
        },
        watch: {
            currentSetting() {
                //this.$refs.configList.$el.scrollTop = 0;
                this.toggleTodoSidebar();
            },
            windowWidth() {
                this.setSidebarWidth()
            },
        },
        computed: {
            windowWidth() {
                return this.$store.state.windowWidth
            },
            currentSetting() {
                if(!this.$route.params.setting) {
                    return this.menuItems[0].name
                }

                return this.$route.params.setting;
            },
            baseUrl() {
                let path = this.$route.path;

                path = path.slice(0, path.lastIndexOf("/"));

                if(!path) {
                    return this.$route.path;
                }

                return path;
            },
            currentComponent() {
                const menuItem = this.menuItems.find(item => item.name === this.currentSetting);

                return menuItem.component
            }
        },
        methods: {
            setSidebarWidth() {
                if (this.windowWidth < 992) {
                    this.isSidebarActive = this.clickNotClose = false
                } else {
                    this.isSidebarActive = this.clickNotClose = true
                }
            },
            toggleTodoSidebar(value = false) {
                if (!value && this.clickNotClose) return;
                this.isSidebarActive = value
            },
        },
    }
</script>

<style scoped>

</style>